import logo from './logo.svg';
import './App.css';
import ListPictures from './Components/ListOfPicture';
import Navbar from './Components/Navbar';

function App() {
  return (
    <div>
      <Navbar/>
      <ListPictures/>
    </div>
  );
}

export default App;
