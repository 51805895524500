import React, { useState, useEffect } from 'react';
import "./Navbar.scss";
import { animate, motion } from 'framer-motion';
import { HiMenuAlt4, HiX } from "react-icons/hi";
import { navLinks } from '../Components/Data';
import { socialIcons } from '../Components/Data';
const Navbar = () => {
    
    const [scroll, setScroll] = useState(false);
    const [toggle, setToggle] = useState(false);
    
    const menuVariants = {
        hidden: {
            scale: 0
        },
        visible: {
            scale: 50,
            transition: {
                type: "tween",
                duration: 0.5,
            }
        }
    }
    const navLinkVariants = {
        hidden: {
            display: 'none',
            pointerEvents: 'none',
            opacity: 0
        },
        visible: {
          display:"flex",
            opacity: 1,
            y: -30,
            transition: {
                delay: 0.7
            }
        }
    }

    const handleMenuClick = () => {
      window.location.href = "https://arunangshudas.com/"; // Replace "/your-link" with the desired link
    };

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 20)
        })
    },[])
  return (
    <motion.div
      initial={{ y: -25 }}
      animate={{ y: -5 }}
      transition={{ duration: 0.5 }}
      className={scroll ? "header active" : "header"}
    >
      <div className="Nav_container">
        <div className="logo">
          <h3>AD</h3>
        </div>
        <ul className="nav_links">
          <li>
            <a
              href="https://arunangshudas.com/"
              rel="noreferrer"
            >
              {" "}
              Home{" "}
            </a>
          </li>
          <li>
            <a
              href="https://contact.arunangshudas.com/"
              rel="noreferrer"
            >
              {" "}
              Contact{" "}
            </a>
          </li>
          <li>
            <a
              href="https://blog.arunangshudas.com/"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              Blog{" "}
            </a>
          </li>
        </ul>
        <div className="social_icons">
          {socialIcons.map((socialIcon) => (
            <a key={socialIcon.id} href={socialIcon.url}>
              {socialIcon.icon}
            </a>
          ))}
        </div>
        <div className="menu">
          <HiMenuAlt4
            onClick={() => {
              handleMenuClick();
            }}
          />
        </div>    
      </div>
    </motion.div>
  );
}

export default Navbar