import React, { useEffect, useState } from 'react';
import AWS from 'aws-sdk'; 
import ImageGallery from "react-image-gallery";
import 'react-image-gallery/styles/css/image-gallery.css';

const galleryStyles = {
  gallery: {
    backgroundColor: "#f4f4f4", // Light gray background
    borderRadius: "12px", // Slightly rounded corners
    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)", // Soft shadow for depth
    overflow: "hidden", // Ensures content stays within the gallery
    position: "relative", // Needed for hover effects
  },
  thumbnail: {
    borderRadius: "8px", // Rounded corners for thumbnails
    opacity: 0.8, // Reduced initial opacity
    transition: "opacity 0.3s ease-in-out", // Smooth opacity change on hover
    cursor: "pointer", // Indicate clickable thumbnails
    position: "absolute", // Needed for hover effects
    bottom: "20px", // Position thumbnails at the bottom
    left: "20px", // Position thumbnails on the left
    padding: "5px", // Add padding for better separation
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent black background
    zIndex: 1, // Ensure thumbnails are above the image
  },
  "thumbnail:hover": { // Styles applied on hover
    opacity: 1, // Full opacity on hover
  },
  image: { // Add styles specifically for the images
    position: "absolute",  // Overlaps thumbnails for cool effect
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover", // Ensure images cover the entire area
    transition: "transform 0.5s ease-in-out", // Smooth image transition
  },
  arrows: { // Add styles for navigation arrows (if applicable)
    display: "none", // Hide default arrows (optional)
  },
  dots: { // Style the image navigation dots
    position: "absolute",
    bottom: "20px",
    left: "50%",
    transform: "translateX(-50%)", // Center the dots
    display: "flex",
    justifyContent: "center",
    zIndex: 1, // Ensure dots are above the image
  },
  dot: { // Style each navigation dot
    backgroundColor: "rgba(255, 255, 255, 0.6)", // Semi-transparent white dots
    margin: "0 5px",
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    cursor: "pointer", // Indicate clickable dots
    transition: "background-color 0.2s ease-in-out",
    zIndex: 1, // Ensure dots are above the image
  },
  "dot.active": { // Style the active navigation dot
    backgroundColor: "rgba(255, 255, 255, 0.9)", // Slightly more opaque white for active dot
  },
};


const ListPictures = () => {
  const [images, setImages] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const s3 = new AWS.S3({
      accessKeyId: 'AKIA4YY2WLS47CSGXMF6',
      secretAccessKey: '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',
      region: 'us-east-1'
    });

    const params = {
      Bucket: 'mygalleryallimage',
    };

    s3.listObjectsV2(params, (err, data) => {
      if (err) {
        console.error('Error fetching images from S3:', err);
        setError('Error fetching images from S3. See console for details.');
      } else {
        const imageObjects = data.Contents.filter(obj => obj.Key.endsWith('.jpg') || obj.Key.endsWith('.jpeg') || obj.Key.endsWith('.png')); // Filter for image files
        if (imageObjects.length === 0) {
          setError('No images found in the specified folder.');
        } else {
          const imageUrls = imageObjects.map(obj => {
            return {
              original: `https://${params.Bucket}.s3.${s3.config.region}.amazonaws.com/${obj.Key}`,
              thumbnail: `https://${params.Bucket}.s3.${s3.config.region}.amazonaws.com/${obj.Key}`
            };
          });
          setImages(imageUrls);
        }
      }
    });
  }, []);

  if (error) {
    return <div>{error}</div>;
  }

  // return (
  //   <div>
  //     <ImageGallery items={images} />
  //   </div>
  // );

  return (
    <div className="beautiful-image-gallery">
      {/* Render the ImageGallery component */}
      <ImageGallery
        items={images}
        showThumbnails={true} // Show thumbnails
        showPlayButton={false} // Hide play button
        showFullscreenButton={false} // Hide fullscreen button
        autoPlay={true} // Auto play images
        slideInterval={5000} // Slide interval
        lazyLoad={true} // Enable lazy loading
        additionalClass="my-image-gallery" // Additional custom class
        styles={galleryStyles} // Apply custom styles
      />
    </div>
  );
};

export default ListPictures;
