import {
    FaGithub,
    FaFacebookF,
    FaTwitter,
    FaInstagram,
    FaLinkedin,
    FaYoutube,
  } from "react-icons/fa";
  import { SiLeetcode } from "react-icons/si";
  
  export const navLinks = ["home", "portfolio"];

  export const socialIcons = [
    {
      id: 1,
      icon: <FaFacebookF />,
      url: "https://www.facebook.com/arunangshu.smile/",
    },
    {
      id: 2,
      icon: <FaInstagram />,
      url: "https://www.instagram.com/call_me_arunangshu/",
    },
    {
      id: 3,
      icon: <FaLinkedin />,
      url: "https://www.linkedin.com/in/arunangshu-das/",
    },
    { id: 4, icon: <FaTwitter />, url: "https://twitter.com/real_arunangshu" },
    { id: 5, icon: <FaGithub />, url: "https://github.com/Arunangshu-Das" },
    { id: 6, icon: <SiLeetcode />, url: "https://leetcode.com/Arunangshu_Das/" },
    {
      id: 7,
      icon: <FaYoutube />,
      url: "https://www.youtube.com/@codewitharunangshu",
    },
  ];
  